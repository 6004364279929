import React, { FC, useEffect, useState } from 'react';
import './application-progress.styles.scss';
import StepsCard from '../../components/application-steps/steps-card/steps-card.component';
import Layout from '../../components/general/layout-component/layout.component';
import InfoCard from '../../components/general/info-card/info-card.component';
import personReading from '../../assets/icons/info-card/person-reading.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';
import { handleNext } from '../../utils/utilsFunctions';
import { setConsentModalState } from '../../redux/Applicant/applicant.actions';
import MainHeader from '../../components/header/main-header/main-header.component';
import PageFooter from '../../components/general/page-footer/page-footer.component';
import { setApplicationStatus } from '../../redux/General/general.actions';
import { createPortal } from 'react-dom';
import * as apiMisc from '../../api/requests/misc.api';
import * as utils from '../../utils/utilsFunctions';
import { fireGaEvent } from '../../utils/ga-event';
import useGetPendingSteps from '../../utils/useGetPendingSteps';
import { useNavigate, useLocation } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import MainSteps from '../../components/main-steps/main-steps.component';

const overlay = document.getElementById('overlay-root') as HTMLElement;
const OverlayComponent = () => {
	useEffect(() => {
		utils.instantScroll();
		const body = document.querySelector('body');
		if (body) {
			disableBodyScroll(body);
		}
		return () => {
			if (body) {
				enableBodyScroll(body);
			}
		};
	}, []);
	return createPortal(
		<div className='edit-application-overlay'></div>,
		overlay,
	);
};

type StepDataType = {
	title: string;
	status: string;
	tag?: string;
};

const ApplicationProgress: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();
	const [showConsentModal, setShowConsentModal] = useState(false);
	const [editApplication, setEditApplication] = useState(false);
	const [readyToSubmit, setReadyToSubmit] = useState(false);
	const [rtwExpandable, setRtwExpandable] = useState(false);
	const [stepData, setStepData] = useState<StepDataType[]>([]);

	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);
	const steps = useSelector(
		(state: RootState) => state.general.stepsComplete,
	);
	const applicationStatus = useSelector(
		(state: RootState) => state.general.applicationStatus,
	);
	const consentModalState = useSelector(
		(state: RootState) => state.applicant.consentModalState,
	);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	const idStepStatus = useSelector(
		(state: RootState) => state.rtw.idCheckStatus,
	);
	const rtwStepStatus = useSelector(
		(state: RootState) => state.rtw.rtwStatus,
	);

	const bs7858 = applicant.check ? applicant.check.bs7858 : 'undefined';
	const cvOnline =
		applicant.flow && applicant.flow === 'cv-online' ? true : false;

	useEffect(() => {
		setStepData(steps);
		utils.instantScroll();
	}, []);

	useEffect(() => {
		checkDropdown();
	}, [rtwStepStatus.checkName]);

	const checkDropdown = () => {
		if (
			rtwStepStatus.checkName === 'manualUploadStatus' ||
			rtwStepStatus.checkName === 'shareCodeStatus' ||
			rtwStepStatus.checkName === 'onfido'
		) {
			setRtwExpandable(true);
		} else {
			setRtwExpandable(false);
		}
	};

	useGetPendingSteps();

	useEffect(() => {
		setShowConsentModal(consentModalState);
	}, [consentModalState]);

	useEffect(() => {
		if (!showConsentModal) {
			dispatch(setConsentModalState(showConsentModal));
		}
	}, [showConsentModal]);

	useEffect(() => {
		const status = steps.reduce(
			(isComplete: boolean, currentValue: any) => {
				if (
					currentValue.status === 'incomplete' ||
					currentValue.status === 'in-progress'
				) {
					isComplete = false;
					return isComplete;
				}
				return isComplete;
			},
			true,
		);
		setReadyToSubmit(status);
	}, [JSON.stringify(steps)]);

	useEffect(() => {
		if (state?.goTo) {
			handleNext(
				state?.goTo,
				dispatch,
				setConsentModalState,
				applicationStatus,
				navigate,
			);
		}
	}, []);

	const titles: StepDataType[] = utils.changeStepTitle(
		stepData,
		'Licences',
		'SIA Licences',
	);

	const getGAPageName = () => {
		if (readyToSubmit) {
			return 'Home - Ready to Submit';
		}
		switch (applicationStatus) {
			case 'not-started':
				return 'Home - New User';
			case 'terms-agreed':
				return 'Home - Returning User';
			default:
				return "Home - Couldn't get status";
		}
	};

	fireGaEvent('Home', getGAPageName());

	const handleShowReview = () => {
		return apiMisc
			.setApplicationStatus('review-required')
			.then(() => {
				fireGaEvent('Home', 'Taps on submit button');
				dispatch(setApplicationStatus('review-required'));
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const handleEditApplication = () => {
		fireGaEvent('Home', 'Taps on edit application');
		setEditApplication(true);
	};

	const handleOpenFaq = () => {
		fireGaEvent('Home', 'Taps open FAQ card');
		window.open(
			'https://intercom.help/deploi/en/collections/9332235-candidate-help',
			'_blank',
		);
	};

	const openRtwStep = () => {
		if (!rtwExpandable) {
			navigate('/rtw/rtw-intro', {
				state: {
					page:
						rtwStepStatus.status === 'incomplete'
							? 'intro'
							: rtwStepStatus.page,
				},
				replace: true,
			});
		}
	};

	const openRtwDropdown = () => {
		const obj = {
			state: {
				page: rtwStepStatus.page,
			},
			replace: true,
		};
		if (!obj.state.page) {
			return;
		}
		navigate('/rtw/rtw-intro', obj);
	};

	const openIdentityDropdown = () => {
		const obj = {
			state: {
				page: idStepStatus.page
					? idStepStatus.page
					: `passport-online-id`,
			},
			replace: true,
		};
		navigate('/rtw/rtw-intro', obj);
	};

	const disableIdentityCheck = () => {
		if (rtwStepStatus.checkName !== 'manualUploadStatus') {
			return rtwStepStatus.status !== 'complete' ? true : false;
		} else {
			return false;
		}
	};

	return (
		<Layout background=''>
			<MainHeader
				actionCardText={null}
				actionCardEmphasisText={null}
				actionCardSubText={null}
			/>
			<div className='application-progress-page-container'>
				<div className='application-progress-page'>
					<h4 className='margin-bottom-8'>Steps to complete</h4>
					<MainSteps
						title='Right to work and identity check'
						stepName='Step 1'
						expandable={rtwExpandable ? true : false}
						clickFunction={openRtwStep}
						status={
							rtwStepStatus.checkName === 'yotiRtwStatus'
								? rtwStepStatus.status
								: undefined
						}
					>
						<StepsCard
							title='Right to work check'
							status={rtwStepStatus.status}
							clickFunction={openRtwDropdown}
							tag=''
						/>
						<StepsCard
							title='Identity check'
							status={idStepStatus.status}
							clickFunction={openIdentityDropdown}
							tag=''
							disabled={disableIdentityCheck()}
						/>
					</MainSteps>
					{(bs7858 || bs7858 == 'undefined') && !cvOnline && (
						<MainSteps
							title='Background check'
							expandable={true}
							stepName='Step 2'
							clickFunction={() => console.log('')} // Props is defined in MainSteps components
							disabled={false}
						>
							{titles &&
								titles.length > 0 &&
								titles.map((step) => {
									return (
										<div key={step.title}>
											<StepsCard
												title={step.title}
												status={step.status}
												clickFunction={() =>
													handleNext(
														step.title ===
															'SIA Licences'
															? 'Licences'
															: step.title,
														dispatch,
														setConsentModalState,
														applicationStatus,
														navigate,
													)
												}
												tag={step.tag}
											/>
										</div>
									);
								})}
						</MainSteps>
					)}

					<div className='margin-bottom-64 applicant-progress-spacer' />
					<div className='applicant-progress-page-info-mobile'>
						<InfoCard
							size={'large'}
							text={
								'Explore our FAQ’s or contact a member of our team'
							}
							icon={personReading}
							heading={'Need a helping hand?'}
							clickFunction={handleOpenFaq}
						/>
					</div>
				</div>
				<div className='applicant-progress-page-info'>
					<InfoCard
						size={'large'}
						text={
							'Explore our FAQ’s or contact a member of our team'
						}
						icon={personReading}
						heading={'Need a helping hand?'}
						clickFunction={handleOpenFaq}
					/>
				</div>
			</div>
			{((rtwStepStatus.status === 'complete' &&
				idStepStatus.status === 'complete') ||
				(rtwStepStatus.checkName === 'manualUploadStatus' &&
					rtwStepStatus.status === 'in-progress' &&
					idStepStatus.status === 'complete')) && (
					<>
						{!editApplication && readyToSubmit && <OverlayComponent />}
						{readyToSubmit && (
							<PageFooter
								primaryButtonText='Submit'
								secondaryButtonText='Edit'
								primaryButtonFunction={handleShowReview}
								secondaryButtonFunction={handleEditApplication}
								editApplication={editApplication}
							/>
						)}
					</>
				)}

			<div
				className={isMobile ? 'margin-bottom-120' : 'margin-bottom-40'}
			/>
		</Layout>
	);
};

export default ApplicationProgress;
